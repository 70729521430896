import React from "react";
import Field from "./field.js";

const Multiselect = (props) => {
  const validationFunction = (value) => {
    return value.length;
  };
  return (<Field
    {...props}
    tag="select"
    multiple={ true }
    defaultClassName="textarea"
    defaultValue={ props.defaultValue ? [props.defaultValue] : [] }
    validationFunction={ validationFunction }
  >
    {
      props.choices.map((choice, i) => {
        return (<option key={i} value={ choice.value }>{ choice.text }</option>);
      })
    }
  </Field>);
};

export default Multiselect;
